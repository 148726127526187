.bill-content-section {
    margin-left: 15px;
    margin-bottom: 0px;
    margin-top: 5px;
    line-height: 20px;
    border-left: 1px dashed gray;
    padding-left: 10px;
}

.bill-content-continue {
    margin-left: 20px;
    margin-bottom: 0px;
    display: block;
}

.bill-content-section-display {
    font-weight: bolder;
}

.anchor-list-bad {
    text-decoration: line-through;
    color: lightgray;
}

.anchor-list-link {
    cursor: pointer;
}

.appropriation-title {
    margin-top: 0px;
}

.vote-result {
    .vote-title {
        margin-top: 0px;
    }

    .vote-data {
        width: 100%;
    }

    .bp5-tag {
        text-align: center;
    }
}
