.usc-content-section {
    margin-left: 15px;
    margin-bottom: 0px;
    margin-top: 5px;
    border-left: 1px dashed gray;
    padding-left: 10px;
}

.usc-content-continue {
    margin-left: 20px;
    margin-bottom: 0px;
    display: block;
}

.usc-content-section-display {
    font-weight: bolder;
}
