span[class*="action-"] {
    background-color: rgba(255, 0, 212, 0.3);
}

span.action-redesignation {
    background-color: rgba(0, 253, 84, 0.3);
}
span.action-target,
span.action-target_section {
    background-color: rgba(20, 67, 194, 0.3);
}

span.action-within {
    background-color: rgba(54, 192, 20, 0.3);
}

span.action-to_remove_text,
span.action-to_remove_section {
    background-color: rgba(255, 0, 0, 0.3);
}

span.action-to_replace,
span.action-to_insert_text {
    background-color: rgba(20, 194, 194, 0.3);
}

span.action-effective_date {
    background-color: rgba(168, 194, 20, 0.3);
}

span.action-context_type,
span.action-title {
    background-color: rgba(0, 255, 0, 0.3);
}
